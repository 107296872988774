.backGround {
  background-color: var(--text-background-color);
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px 0px 20px 0px;
}

.backGround img {
  width: 100%;
  max-width: 120px;
  margin: 30px auto;
}

.loginForm h3 {
  text-align: center;
  color: #262626;
  font-size: 30px;
  font-weight: 800;
  margin-top: 30px;
}

.FormGroup {
  position: relative;
  margin-bottom: 20px;
}

.FormLabel {
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
}

.FormInput {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-top: 40px;
}

.FormGroup .FormInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.FormInput:focus {
  outline: none;
  border: 1px solid #dedede;
}

.forgotPassword {
  text-align: right;
  margin-top: -7px;
  margin-right: 5px;
}

.forgotPassword a {
  color: #333333;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
}

.loginBtn button {
  text-align: center;
  margin-top: 30px;
  padding: 14px 10px;
  background-color: var(--text-background-color);
  border-radius: 7px;
  width: 100%;
  color: #fff;
  border: 1px solid var(--text-background-color);
  font-size: 17px;
  font-weight: 600;
}

.otherOption p {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #828282;
  margin-top: 30px;
}

.socialIcon {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.socialOption img {
  width: 100%;
  max-width: 40px;
  margin-right: 10px;
}

.havaAccount {
  text-align: center;
}

.havaAccount .accText {
  color: #626262;
  font-size: 11px;
  font-weight: 500;
  margin-top: 35px;
  margin-left: 10px;
}

.accText a {
  color: var(--text-background-color);
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  margin-left: 8px;
}

@media screen and (min-width: 768px) {
  .loginSection {
    height: 100vh;
    width: 100%;
    max-width: 375px;
    margin: 0px auto;
  }
}

@media screen and (max-width: 360px) {
  .backGround {
    padding: 10px 0px 0px 0px !important;
  }

  .backGround img {
    width: 100%;
    max-width: 100px;
    margin: 25px auto;
  }

  .loginForm h3 {
    margin-top: 25px !important;
  }

  .FormInput {
    margin-top: 30px !important;
  }

  .loginBtn button {
    margin-top: 20px !important;
    padding: 10px 10px !important;
  }

  .otherOption p {
    margin-top: 25px !important;
  }

  .languageBtn {
    margin-top: 20px !important;
  }

  .havaAccount .accText {
    margin-top: 28px !important;
  }
  .localOption {
    display: flex;
    position: fixed;
    bottom: 0px;
    margin-bottom: 5px;
  }
}


@media screen and (max-width: 375px) {
  .backGround {
    padding: 10px 0px 0px 0px;
  }

  .backGround img {
    width: 100%;
    max-width: 100px;
    margin: 25px auto;
  }

  .loginForm h3 {
    margin-top: 30px;
  }

  .FormInput {
    margin-top: 30px !important;
    padding: 12px 15px !important;
  }

  .loginBtn button {
    margin-top: 25px;
    padding: 10px 10px;
  }

  .otherOption p {
    margin-top: 30px;
  }

  .languageBtn {
    margin-top: 20px !important;
  }

  .havaAccount .accText {
    margin-top: 28px !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 575px) {
  .backGround {
    padding: 25px 0px 20px 0px;
  }

  .backGround img {
    width: 100%;
    max-width: 100px;
    margin: 25px auto;
  }

  .loginForm h3 {
    margin-top: 30px;
  }

  .FormInput {
    margin-top: 30px !important;
  }
  .localOption {
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .havaAccount .accText {
    color: #626262;
    font-size: 10px;
    font-weight: 400;
  }

  .accText a {
    color: var(--text-background-color);
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    margin-left: 8px;
  }
}

.languageBtn {
  margin-top: 30px;
}

.localOption {
  display: flex;
}
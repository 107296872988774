.settingWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  background-color: #f6f6f6;
}

.backGround {
  background-color: var(--text-background-color);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 45px 0px 30px 20px;
  position: relative;
}

.historyList {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 26px;
  padding: 20px 12px;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.topItem h5 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.bellIcon svg {
  position: absolute;
  color: #ffffff;
  top: 50px;
  font-size: 25px;
  right: 10px;
}

.history h3 {
  font-size: 16px;
  font-weight: 700;
  color: #262626;
  text-transform: capitalize;
}

.history p {
  font-size: 14px;
  font-weight: 600;
  color: #626262;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.history span {
  font-size: 14px;
  font-weight: 500;
  color: #828282;
  margin-bottom: 5px;
  margin-left: 5px;
}

.typeText {
  display: flex;
}

.typeText p {
  font-size: 15px;
  font-weight: 800;
  color: #626262;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.sosImg img {
  width: 100%;
  max-width: 70px;
  /* margin-top: 12px; */
  cursor: pointer;
}

.noResultsMessage {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
}

.lockImg {
  background-color: #c6d9c682;
  border-radius: 12px;
  padding: 20px 25px;
}

.lockImg svg {
  color: #228b22;
  font-size: 22px;
  font-weight: 600;
}

.unlockImg {
  background-color: #62626229;
  border-radius: 12px;
  padding: 20px 25px;
}

.unlockImg svg {
  color: #999999;
  font-size: 22px;
  font-weight: 600;
}

/* vehicle detail page  */
.bikeImage {
  width: 100%;
  /* max-width: 120px; */
  border-radius: 20px;
  /* height: 100px; */
  margin: 0px auto;
  display: flex;
  justify-content: center;
}

.bikeImage img {
  width: 100%;
  max-width: 140px;
  height: 120px;
  border-radius: 17px;
  object-fit: contain;
}

.bikeHistory {
  /* display: flex;
  justify-content: space-between; */
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 26px;
  padding: 20px 20px;
}

/* .topSection {
  background-color: #406394;
  padding: 30px 10px 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
} */

.formStart {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0px auto;
}

.backIcon {
  background-color: #fff;
  border-radius: 50%;
  height: 35px;
  margin-top: -16px;
  max-width: 35px;
  text-align: center;
  width: 100%;
}

.backIcon svg {
  margin-top: 5px;
  color: var(--text-background-color);
  font-size: 25px;
}

.profileTitle p {
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.profileTitle {
  margin: 10px auto;
}

.topSection {
  background-color: var(--text-background-color);
  padding: 30px 10px 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.vehicleText {
  margin-top: 30px;
}

.textOption {
  display: flex;
  justify-content: space-between;
}

.textOption h5 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0px;
}

.textOption p {
  font-size: 16px;
  color: #626262;
  font-weight: 500;
}

.toggleFlex {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.switchOn {
  margin-left: 84px;
}

.kmINput {
  margin-top: 20px;
}

.toggleFlex h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.update button {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 14px 10px;
  border: 1px solid var(--text-background-color);
  background-color: var(--text-background-color);
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}

.update {
  margin-top: 20px;
}

.bikeDetail {
  padding: 12px 12px;
  overflow-x: hidden;
  height: 285px;
  min-height: calc(100vh - 145px);
  scrollbar-width: none;
}

.notificationTick {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #9f9f9f;
  position: absolute;
  top: 50px;
  right: 11px;
  z-index: 1;
}

@media screen and (max-width: 575px) {
  .typeText p {
    font-size: 13px;

  }

}


/*======= history detail page===========  */



.uploadPhoto {
  margin-top: 40px;
}
.uploadPhoto h3 {
  font-size: 16px;
  font-weight: 700;
  color: #202020;
  margin-bottom: 15px;
}

.photoUploadContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.thumbnailContainer {
  position: relative;
  display: inline-block;
}

.thumbnailContainer img {
  border-radius: 3px;
}

.thumbnail {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 3px;
  border: 1px solid #E9E9E9;
  padding: 3px;
}
.removeButton {
  position: absolute;
  top: 8px;
  right: 7px;
  cursor: pointer;
  width: 10px;
  height: 10px;
}

.uploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 0.5px solid #E9E9E9;
  border-radius: 3px;
  background-color: #F6F5F5;
  cursor: pointer;
  font-size: 2rem;
  color: #666;
}

.fileInput {
  display: none;
}


.historytextOption h5 {
  font-size: 16px;
  color: #262626;
  font-weight: 700;
}

.historytextOption p {
  font-size: 14px;
  color: #626262;
  font-weight: 600;
}

.historytextOptionSos p {
  color: #262626;
  font-size: 16px;
  font-weight: 600;
}

.historytextOptionSos span {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
}

.viewBtn button {
  text-align: center;
  margin-top: 30px;
  padding: 14px 10px;
  background-color: var(--text-background-color);
  border-radius: 7px;
  width: 100%;
  color: #fff;
  border: 1px solid var(--text-background-color);
  font-size: 17px;
  font-weight: 600;
}

.notify{
  display: flex;
  justify-content: flex-end;
}

.notify button{
  border: 0.5px solid #8080806e;
  border-radius: 5px;
}
.listBox {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 15px;
  border-radius: 26px;
  padding: 25px 15px;
}
.settingWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  background-color: #f6f6f6;
}

.backGround {
  background-color: var(--text-background-color);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 45px 0px 30px 20px;
  position: relative;
}

.topItem h5 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.bellIcon svg {
  position: absolute;
  color: #ffffff;
  top: 50px;
  font-size: 25px;
  right: 10px;
}

.historyList {
  text-align: center;
}

.historyList h2 {
  color: #000000;
  font-size: 25px;
  font-weight: 700;
}

.historyList p {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.sosServiceImg {
  display: flex;
  justify-content: space-between;
  /* margin-top: 50px; */
}
.sosServiceText {
  display: flex;
}

.theiftImg {
  background-color: #4063941c;
  border: 1px solid #4063941c;
  border-radius: 8px;
  padding: 15px 20px;
  width: 100%;
  max-width: 100px;
}

.theiftImg img {
  width: 100%;
  max-width: 55px;
}

.accidentImg {
  background-color: #4063941c;
  border: 1px solid #4063941c;
  border-radius: 8px;
  padding: 15px 20px;
  width: 100%;
  max-width: 100px;
}
.accidentImg img {
  width: 100%;
  max-width: 55px;
}

.roadSideAccistenceImg {
  background-color: #4063941c;
  border: 1px solid #4063941c;
  border-radius: 8px;
  padding: 15px 20px;
  width: 100%;
}
.roadSideAccistenceImg img {
  width: 100%;
  max-width: 55px;
}

.text p {
  font-size: 11px;
  font-weight: 600;
  color: #262626;
  margin-top: 15px;
  text-align: center;
}

.sosImg {
  width: 100%;
  max-width: 200px;
  margin: 25px auto;
}

.sosImg img {
  width: 100%;
  max-width: 200px;
}

@media screen and (max-width: 339px) {
  .theiftImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-right: 10px;
    width: 100%;
    max-width: 100px;
  }

  .theiftImg img {
    width: 100%;
    max-width: 50px;
  }

  .accidentImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-left: 8px;
    width: 100%;
    max-width: 100px;
  }
  .accidentImg img {
    width: 100%;
    max-width: 50px;
  }

  .roadSideAccistenceImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-left: 15px;
    width: 100%;
    max-width: 85px;
  }
  .roadSideAccistenceImg img {
    width: 100%;
    max-width: 50px;
  }
  .theiftImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-right: 10px;
    width: 100%;
    max-width: 100px;
  }

  .theiftImg img {
    width: 100%;
    max-width: 50px;
  }

  .accidentImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-left: 8px;
    width: 100%;
    max-width: 100px;
  }
  .accidentImg img {
    width: 100%;
    max-width: 50px;
  }

  .roadSideAccistenceImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-left: 15px;
    width: 100%;
    max-width: 85px;
  }
  .roadSideAccistenceImg img {
    width: 100%;
    max-width: 50px;
  }
}

@media screen and (min-width: 340px) and (max-width: 375px) {
  .theiftImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-right: 10px;
    width: 100%;
    max-width: 100px;
  }

  .theiftImg img {
    width: 100%;
    max-width: 55px;
  }

  .accidentImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-left: 8px;
    width: 100%;
    max-width: 100px;
  }
  .accidentImg img {
    width: 100%;
    max-width: 55px;
  }

  .roadSideAccistenceImg {
    background-color: #6750a412;
    border: 1px solid #6750a412;
    border-radius: 8px;
    padding: 15px 15px;
    margin-left: 15px;
    width: 100%;
    max-width: 95px;
  }
  .roadSideAccistenceImg img {
    width: 100%;
    max-width: 55px;
  }
}
.notificationTick {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #9f9f9f;
  position: absolute;
  top: 50px;
  right: 11px;
  z-index: 1;
}

.ResetBtn button {
  text-align: center;
  margin-top: 30px;
  padding: 14px 10px;
  background-color: var(--text-background-color);
  border-radius: 7px;
  width: 100%;
  color: #fff;
  border: 1px solid var(--text-background-color);
  font-size: 17px;
  font-weight: 600;
}

.disabledButton {
  background-color: #dddddd; 
  color: #666666;
  cursor: not-allowed;
}

.tittle {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  width: 100%;
  max-width: 508px;
  margin: 0px auto;
}

.backIcon {
  background-color: #52535b;
  border-radius: 50%;
  text-align: center;
  width: 100%;
  max-width: 30px;
  height: 30px;
  margin-top: -15px;
}

.backIcon svg {
  margin-top: 4px;
  color: #fff;
  font-size: 22px;
}

.signUpTitle p {
  text-align: center;
  font-size: 19px;
  font-weight: 700;
  color: #262626;
}

.signUpTitle img {
  color: #262626;
  margin-left: 8px;
  margin-top: -2px;
}

.signUpTitleBike p {
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  color: #262626;
}

.signUpTitleBike img {
  color: #262626;
  margin-left: 8px;
  margin-top: -4px;
}

.signUpTitle {
  margin: 10px auto;
}

.signUpTitleBike {
  margin: 10px auto;
}

.FormGroup {
  position: relative;
  margin-bottom: 30px;
}

.FormLabel {
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
}

.FormInput {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-right: 21px;
}

.inputForm {
  width: 100%;
  max-width: 320px;
  margin: 0px auto;
}

.FormGroup .FormInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.FormInput:focus {
  outline: none;
  border: 1px solid #dedede;
}

.nextBtn button {
  text-align: center;
  /* margin-top: 30px; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 14px 10px;
  border: 1px solid var(--text-background-color);
  background-color: var(--text-background-color);
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}

.optionalButton button {
  background-color: #40639429;
  border: 1px solid #40639429;
  border-radius: 5px;
  padding: 8px 15px;
  color: var(--text-background-color);
}

.skipButton {
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.otherOption p {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #828282;
  margin-top: 30px;
  /* margin-left: 37px; */
}

.socialIcon {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.socialOption img {
  width: 100%;
  max-width: 40px;
  margin-right: 10px;
}

.havaAccount {
  text-align: center;
}

.havaAccount .accText {
  color: #626262;
  font-size: 14px;
  font-weight: 500;
  margin-top: 35px;
}

.accText a {
  color: var(--text-background-color);
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  margin-left: 8px;
}

.pinFormLabel {
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
}

.stepCount {
  margin-top: 6px;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}
.stepCount span {
  color: #b9b9b9;
  font-size: 16px;
  font-weight: 600;
}

.uploadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadLabel {
  cursor: pointer;
  align-items: center;
  margin-top: 10px;
  color: #33333361;
  font-size: 11px;
  font-weight: 600;
}

.uploadIcon {
  border: 2px solid #dedede;
  padding: 40px 40px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.uploadBox p {
  font-size: 14px;
  font-weight: 600;
  color: #6d6d6d;
}
.uploadIcon svg {
  color: #9e9e9e;
  font-size: 40px;
  font-weight: 600;
  display: flex;
  margin: 10px auto;
}

.uploadImg {
  width: 100%;
  max-width: 267px;
  margin: 20px auto;
  border-radius: 5px;
  height: 100%;
  max-height: 276px;
  object-fit: contain;
}

.imgUpload {
  position: relative;
}

.imgUpload .afterUploadImg {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #dedede;
  padding: 1px 1px;
  border-radius: 7px;
}

.closeIcon {
  font-size: 25px;
  position: absolute;
  left: 181px;
  top: -9px;
  color: #fff;
  background-color: var(--text-background-color);
  font-weight: 600;
  border-radius: 50%;
}


.qrScanner {
  text-align: center;
  margin: 30px auto;
}

.qrScanner p {
  font-size: 14px;
  font-weight: 600;
  color: #6d6d6d;
}

.scannerImg {
  border: 2px solid #dedede;
  border-radius: 5px;
  width: 100%;
  margin: 0px auto;
  max-width: 200px;
}

.scannerImg img {
  width: 100%;
  max-width: 180px;
  margin: 5px 5px;
}

.emergencyContact {
  display: flex;
  justify-content: space-between;
}

.contactText h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .tittle {
    padding: 5px 20px;
  }
}

@media screen and (min-width: 376px) and (max-width: 390px) {
  .tittle {
    padding: 5px 25px;
  }
}

@media screen and (min-width: 391px) and (max-width: 410px) {
  .tittle {
    padding: 5px 35px;
  }
}

@media screen and (min-width: 411px) and (max-width: 414px) {
  .tittle {
    padding: 5px 40px;
  }
}

@media screen and (min-width: 415px) and (max-width: 430px) {
  .tittle {
    padding: 5px 45px;
  }
}

@media screen and (max-width: 575px) {
  .havaAccount .accText {
    color: #626262;
    font-size: 12px;
    font-weight: 400;
  }

  .accText a {
    color: var(--text-background-color);
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    margin-left: 8px;
  }
  .emergencyContact {
    display: flex;
    justify-content: space-between;
    margin-left: 26px;
    margin-right: 13px;
  }
  .emergencyContacts {
    display: flex;
    justify-content: space-between;
    margin-left: 6px;
    margin-right: 13px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .emergencyContact {
    display: flex;
    justify-content: space-evenly;
    margin-left: 26px;
    margin-right: 13px;
  }
  .emergencyContacts {
    display: flex;
    justify-content: space-between;
    margin-left: 6px;
    margin-right: 13px;
  }
}

@media screen and (min-width: 768px) {
  .signpWrapper {
    width: 100%;
    max-width: 375px;
    margin: 0px auto;
  }
  .emergencyContact {
    display: flex;
    justify-content: space-between;
    margin-left: 26px;
    margin-right: 13px;
  }
  .emergencyContacts {
    display: flex;
    justify-content: space-between;
    margin-left: 6px;
    margin-right: 13px;
  }
}

.uploadIconImg {
  border: 2px solid #dedede;
  padding: 50px 32px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.FormInputs {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-right: 21px;
}

/* 
.pinInputText{
 margin-top: -10px;
 margin-bottom: 30px;
} */

.customDropdown {
  position: absolute !important;
  top: -200px; /* Adjust this value as needed */
  left: 0;
}

/* alert modal css */

.goBack {
  font-size: 17px;
  font-weight: 400;
  color: #828282;
  text-align: center;
}

.Btns {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.cancelBtn {
  background-color: #b6b6b6;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid #b6b6b6;
  margin-right: 12px;
  padding: 8px 25px;
  color: #fff;
}

.deleteBtn {
  background-color: var(--text-background-color);
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid var(--text-background-color);
  margin-right: 12px;
  padding: 8px 40px;
  color: #fff;
}

.hintMsg{
  color: #6d6d6d;
  font-size: 13px;
  font-weight: 500;
}

/* Add a style for disabled button */
.disabledButton {
  background-color: #dddddd; 
  color: #666666;
  cursor: not-allowed;
}

/* .nextBtn button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
} */
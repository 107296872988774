.iconText {
  text-align: center;
  /* background-color: #4063941f; */
  border-radius: 50%;
  width: 100%;
  max-width: 100px;
  height: 100px;
  margin: 0px auto;
}
.modalTitle {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.iconText img {
  margin-bottom: 10px ;
  width: 100%;
  max-width: 100px;
}

.alertBox h6 {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  margin-left: 26px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.lockPin {
  text-align: center;
}
.submitBtns button {
  background-color: var(--text-background-color);
  border-radius: 10px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid var(--text-background-color);
  padding: 12px 25px;
  color: #fff;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

@media screen and (min-width: 320px) and (max-width: 390px) {
  .alertBox h6 {
    margin-left: 8px !important;
    margin-bottom: 12px !important;
    margin-top: 12px;
  }
}

@media screen and (min-width: 391px) and (max-width: 414px) {
  .alertBox h6 {
    margin-left: 14px !important;
    margin-bottom: 12px !important;
    margin-top: 12px;
  }
}


.disabledButton {
  background-color: #dddddd; 
  color: #666666;
  cursor: not-allowed;
}

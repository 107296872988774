.topSection {
  background-color: var(--text-background-color);
  padding: 10px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.backIcon {
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  width: 100%;
  max-width: 30px;
  height: 30px;
  margin-top: -16px;
}

.forgotText p {
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}

.forgotText {
  margin: 10px auto;
}

.formStart {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0px auto;
}

.backIcon svg {
  margin-top: 5px;
  color: var(--text-background-color);
  font-size: 20px;
}

.lockImg {
  width: 100%;
  max-width: 150px;
  margin: 20px auto;
}

.lockImg img {
  width: 100%;
  max-width: 150px;
}

.tittleText {
  text-align: center;
}

.tittleText h3 {
  font-size: 28px;
  font-weight: 800;
  color: #262626;
  margin-bottom: 20px;
}

.tittleText p {
  font-size: 14px;
  font-weight: 400;
  color: #626262;
  width: 100%;
  max-width: 350px;
  margin: 0px auto;
}

.pinInput {
  text-align: center;
  margin-top: 15px;
}

.codeLabel p {
  margin-top: 27px;
  text-align: center;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #626262;
}

.submitBtn button {
  text-align: center;
  margin-top: 60px;
  padding: 15px 10px;
  border: 1px solid var(--text-background-color);
  background-color: var(--text-background-color);
  border-radius: 10px;
  width: 100%;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
}

.textAreaClass {
  padding: 0px;
}

.disabledButton {
  background-color: #dddddd; 
  color: #666666;
  cursor: not-allowed;
}

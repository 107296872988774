@media screen and (min-width: 768px) {
  .listWrapper {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    background-color: #f6f6f6;
  }
}

@media screen and (max-width: 389px) {
  .listWrapper {
    background-color: #f6f6f6;
  }

  .rightItem h5 {
    margin-left: 45px;
  }

  .text h5 {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 410px) {
  .listWrapper {
    height: 100vh;
    background-color: #f6f6f6;
  }

  .rightItem h5 {
    margin-left: 45px;
  }

  .text h5 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 411px) and (max-width: 412px) {
  .listWrapper {
    height: 100vh;
    background-color: #f6f6f6;
  }

  .optionList {
    /* margin-top: 18px !important; */
  }

  .rightItem h5 {
    margin-left: 45px;
  }

  .text h5 {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 413px) and (max-width: 539px) {
  .listWrapper {
    height: 100vh;
    background-color: #f6f6f6;
  }

  .optionList {
    /* margin-top: 20px !important; */
  }

  .rightItem h5 {
    margin-left: 45px;
  }

  .text h5 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 540px) and (max-width: 567px) {
  .listWrapper {
    height: 100vh;
    background-color: #f6f6f6;
  }

  .rightItem h5 {
    margin-left: 45px;
  }

  .text h5 {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .listWrapper {
    height: 100vh;
    background-color: #f6f6f6;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  .listWrapper {
    height: 100vh;
  }
}

.rightItem h5 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-left: 36px;
}

.displayItem {
  display: flex;
  justify-content: space-between;
  background-color: var(--text-background-color);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 45px 8px 30px 20px;
}

.topItem {
  position: relative;
}

.topItem svg {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  font-weight: 600;
}

.topItemRight svg {
  color: var(--text-background-color);
  font-size: 22px;
  font-weight: 600;
  margin-left: 10px;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px 5px;
}

.searchBar .form-control {
  border: none !important;
  box-shadow: none !important;
  font-weight: 400;
  background: #fafafa;
  border-radius: 4px;
  height: 40px;
}

.searchBar .form-control:focus {
  border: none !important;
}

.formGroup {
  margin-top: 12px;
  position: relative;
}

.searchIcon svg {
  color: #000;
  font-weight: 700;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  font-size: 31px;
  top: 13px;
  left: 2px;
}

.searchControl {
  padding: 15px 30px;
  border-radius: 88px;
  width: 100%;
  margin: 0px auto;
  border: 1px solid #fff;
}

.FormGroup .searchControl::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.searchControl:focus {
  outline: none;
  border: 1px solid #dedede;
}

.searchControl:focus {
  box-shadow: none !important;
  border: 1px solid #fff !important;
}

.bikeHistory {
  display: flex;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 26px;
  padding: 10px 10px;
}

.bikeImage {
  margin-right: 12px;
  width: 100%;
  max-width: 120px;
  border-radius: 20px;
  height: 100px;
}

.bikeImage img {
  width: 100%;
  max-width: 120px;
  height: 100px;
  border-radius: 30px;
  object-fit: cover;
}

.text h4 {
  color: #626262;
  font-size: 13px;
  font-weight: 500;
}

.text h5 {
  color: #262626;
  font-size: 16px;
  font-weight: 700;
}

.text p {
  color: #626262;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0px;
}

.bottomItem h4 {
  color: #626262;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.textItem {
  display: flex;
}

.rightText {
  display: flex;
  padding: 6px 0px;
  width: 100%;
}

.bottomItem h3 {
  color: #626262;
  font-size: 13px;
  font-weight: 500;
}

.lockImg {
  background-color: #c6d9c682;
  border-radius: 12px;
  padding: 20px 25px;
}

.lockImg svg {
  color: #228b22;
  font-size: 22px;
  font-weight: 600;
}

.unlockImg {
  background-color: #62626229;
  border-radius: 12px;
  padding: 20px 25px;
}

.unlockImg svg {
  color: #999999;
  font-size: 22px;
  font-weight: 600;
}

.listWrapper h6 {
  color: #000;
  font-size: 22px;
}

.optionList {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 5px;
  /* margin-top: 35px; */
}

.noResultsMessage {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
}

.circleClass {
  border-radius: 50px;
  width: 40px;
  max-width: 40px;
}

.text .geoFenceStatus {
  color: #262626 !important;
  font-size: 11px;
  font-weight: 700;
}

.text .geoFenceStatus span {
  color: #626262 !important;
  font-size: 11px;
  font-weight: 500;
}
.notificationTick {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* border: 1px solid red; */
  position: absolute;
  left: 12px;
  top: 1px;
  background: #9f9f9f;
}

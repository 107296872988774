.iconText {
  text-align: center;
  background-color: #ea43351f;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0px auto;
}

.iconText svg {
  font-size: 40px;
  font-weight: 600;
  margin: 14px auto;
}

.alertBox {
  text-align: center;
}

.alertBox h6 {
  margin: 10px auto;
  color: #000;
  font-size: 19px;
  font-weight: 700;
}

.deletAcc {
  font-size: 17px;
  font-weight: 400;
  color: #828282;
  text-align: center;
}

.Btns {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.cancelBtn {
  background-color: #b6b6b6;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid #b6b6b6;
  margin-right: 12px;
  padding: 8px 25px;
  color: #fff;
}

.deleteBtn {
  background-color: var(--text-background-color);
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid var(--text-background-color);
  margin-right: 12px;
  padding: 8px 40px;
  color: #fff;
}

.lockSubmitBtn button {
  background-color: var(--text-background-color);
  border-radius: 10px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid var(--text-background-color);
  padding: 12px 25px;
  color: #fff;
  width: 100%;
  margin-top: 20px;
}

.lockCancelBtn button {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin-top: 20px;
  border: none;
  background-color: #fff;
  color: #000000;
}

.enterPin {
  font-size: 18px;
  font-weight: 400;
  color: #828282;
  text-align: center;
}

.lockPin {
  width: 100%;
  margin-top: 20px;
  margin: 15px auto !important;
  max-width: 320px;
}

.forgotPin p {
  text-align: end;
  /* margin-top: -11px; */
  margin-right: 30px;
  font-size: 12px;
  font-weight: 500;
  color: #333333;
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .forgotPin p {
    text-align: end;
    margin-top: -9px;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 361px) and (max-width: 375px) {
  .forgotPin p {
    text-align: end;
    margin-top: -9px;
    margin-right: 13px !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 390px) {
  .forgotPin p {
    text-align: end;
    margin-top: -9px;
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 391px) and (max-width: 411px) {
  .forgotPin p {
    text-align: end;
    margin-top: -9px;
    margin-right: 13px !important;
  }
}

@media screen and (min-width: 412px) and (max-width: 414px) {
  .forgotPin p {
    text-align: end;
    margin-top: -9px;
    margin-right: 23px !important;
  }
}

.languageSelection {
  padding: 12px 12px;
}

.languageSelection h5 {
  color: #000;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.disabledButton {
  background-color: #dddddd;
  color: #666666;
  cursor: not-allowed;
}

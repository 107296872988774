body {
  margin: 0;
  /* font-family: "Inter", sans-serif !important; */
  font-family: "Nunito", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --text-background-color: #FF2A00;
}
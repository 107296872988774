.noResultsMessage {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
}

.doneBtn button {
  background-color: var(--text-background-color);
  border-radius: 10px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid var(--text-background-color);
  padding: 12px 25px;
  color: #fff;
  width: 100%;
  margin-top: 20px;
}

.cancel button {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin-top: 20px;
  border: none;
  background-color: #fff;
  color: #000000;
}

.vehicleInputDisplay {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.vehicleInputDisplay .vehicleInputText h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #202020;
  text-transform: capitalize;
}

.vehicleInputDisplay .vehicleInputText p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
}

.vehicleInputDisplay img {
  width: 100%;
  max-width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 15px;
}

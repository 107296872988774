.mapWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  background-color: #f6f6f6;
}

.backGround {
  background-color: var(--text-background-color);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 45px 0px 30px 20px;
  position: relative;
}

.topItem h5 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.bellIcon svg {
  position: absolute;
  color: #fff;
  top: 50px;
  font-size: 25px;
  right: 10px;
}

.optionBike {
  text-align: center;
}

.bikeImage {
  margin: 0px auto;
  width: 100%;
  max-width: 120px;
  border-radius: 20px;
  height: 100px;
}

.bikeImage img {
  width: 100%;
  max-width: 120px;
  height: 100px;
  border-radius: 30px;
  object-fit: cover;
}

.notificationTick {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #9f9f9f;
  position: absolute;
  top: 50px;
  right: 11px;
  z-index: 1;
}

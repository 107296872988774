/* ----- useForm validation error message css  -----*/
.errorMsg {
  color: red;
}

.patternErrorMsg {
  color: #6d6d6d;
  font-size: 12px;
}

/* .otpErrorMsg{
  color: red;
  margin-left: 25px;

} */

.changeModalErrorMsg {
  color: red;
  text-align: left;
  margin-left: 25px;
  margin-top: 10px;
}

/* signup second step PinInput.css */
.pincode-input-text {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.pinInputText {
  margin-top: -10px;
  margin-bottom: 30px;
}

/* input.pincode-input-text {
  border: 1px solid #dedede;
  width: 54px !important;
}  */

/*  signup form second step datepicker css */
.react-datepicker__input-container {
  position: unset !important;
  width: 320px !important;
}

.form-control:focus {
  box-shadow: none;
  /* border: 1px solid #fff !important; */
}

.CalendarIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-90%);
  cursor: pointer;
  color: #555;
  font-size: 1.2em;
}

.dropDown {
  position: absolute;
  top: 0px;
  right: 7px;
  transform: translateY(47%);
  cursor: pointer;
  color: #555;
  font-size: 30px;
  background-color: #fff;
}
.custom-datepicker .MuiInputLabel-shrink {
  transform: translateY(-10px);
  position: absolute;
  left: 14px;
  padding: 0px 2px;
}

.passwordicon {
  color: #958b8b;
  right: 13px;
  top: 15px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
}

/* mui datepicker css */
.dateOfBirth .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #6d6d6d !important;
  padding: 0px 5px !important;
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
}

.dateOfBirth .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #6d6d6d;
  border: none !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dedede !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #6d6d6d !important;
}

.dateOfBirth
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dedede !important;
}

/* blood type selection option css */
.bloodTypeChek .form-check-input[type="radio"] {
  display: none;
}
.checkboxSelect {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.checkboxSelect .input {
  padding-left: 0px !important;
}

.checkboxSelect.selected {
  background-color: var(--text-background-color);
}

.checkboxSelect label {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding: 5px;
}

/* ----- modal popup css ----------- */
.modal-content {
  width: 100% !important;
  max-width: 400px !important;
  margin: 0px auto !important;
}

.modalCustom .modal-content {
  width: 100% !important;
  max-width: 380px !important;
  margin: 0px auto !important;
}

.modalCloseBtn .btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* ------ signup form phone input css --------*/
.phone-input-container {
  margin-bottom: 15px;
}

.phone-input-container .react-tel-input .form-control {
  width: 100% !important;
  height: 55px !important;
}

.phone-input-container .react-tel-input .flag-dropdown {
  background-color: #fff !important;
}

.phone-input-container .react-tel-input .form-control {
  border: 1px solid #dedede !important;
}

.react-tel-input .special-label {
  display: block !important;
  padding: 0 2px !important;
  color: #6d6d6d !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.defaultText .nav-link {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-background-color);
  text-decoration: underline;
}

.defaultText .nav-link .active {
  color: blue;
}

/* reset password otp input css */
.otpbox input::placeholder {
  font-size: 25px;
  color: #878787;
}

.otpInput {
  display: flex;
  justify-content: center;
}

.inputStyle {
  width: 40px !important;
  height: 40px;
  /* margin: 0 1rem; */
  margin: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.inputStyle:focus-visible {
  box-shadow: none !important;
  outline: none;
}

/* ------- media query css for manage responsive ---------*/
@media screen and (max-width: 575px) {
  .pincode-input-text {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .react-datepicker__input-container {
    width: 336px !important;
  }
  .dateOfBirth .css-4jnixx-MuiStack-root {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 576px) {
  .pinInputText input.pincode-input-text {
    width: 53px !important;
  }

  .lockPinINput input.pincode-input-text {
    width: 53px !important;
  }

  .dateOfBirth .css-4jnixx-MuiStack-root {
    flex-direction: column;
  }
}

@media screen and (max-width: 340px) {
  .lockPinINput input.pincode-input-text {
    width: 45px !important;
  }

  .pinInputText input.pincode-input-text {
    border: 1px solid #dedede !important;
    width: 49px !important;
  }
  .changeModalErrorMsg {
    margin-left: 10px;
  }
}

@media screen and (min-width: 341px) and (max-width: 359px) {
  .pinInputText input.pincode-input-text {
    width: 51px !important;
  }

  .lockPinINput input.pincode-input-text {
    width: 48px !important;
  }

  .changeModalErrorMsg {
    margin-left: 11px;
  }
}

@media screen and (min-width: 360px) and (max-width: 374px) {
  .pinInputText input.pincode-input-text {
    width: 53px !important;
  }

  .lockPinINput input.pincode-input-text {
    width: 51px !important;
  }

  .changeModalErrorMsg {
    margin-left: 11px;
  }
}

@media screen and (min-width: 375px) and (max-width: 575px) {
  .pinInputText input.pincode-input-text {
    width: 53px !important;
  }

  .lockPinINput input.pincode-input-text {
    width: 53px !important;
  }

  .changeModalErrorMsg {
    margin-left: 11px;
  }
}

/* react select ccs */
.css-b62m3t-container {
  position: unset !important;
}

.css-13cymwt-control {
  border: none !important;
}

.css-13cymwt-controls {
  border: unset !important;
  box-shadow: unset !important;
}

.css-13cymwt-control:hover {
  border: unset !important;
  box-shadow: unset !important;
}

.activeContactDefult a {
  color: red !important;
}

.active {
  color: var(--text-background-color) !important;
}

.itemLink {
  color: #c5c5c5;
  font-size: 25px;
  font-weight: 800;
}

button.btn-close {
  background-color: #8282827a !important;
  border-radius: 50% !important;
  color: #fff !important;
}

/* .homeListFooter {
  min-height: calc(100vh - 125px);
  overflow-x: hidden;
} */

.tabSection button {
  font-size: 12px;
}

.mapItem {
  overflow-x: hidden;
  height: 220px;
  /* min-height: calc(100vh - 247px); */
  scrollbar-width: none;
    min-height: calc(100vh - 213px);

}

/* .leaflet-container {
  height: 438px !important;
  width: 100%;
  position: relative;
} */

.fixContent {
  padding: 5px 12px;
  overflow-x: hidden;
  height: 220px;
  /* min-height: calc(100vh - 247px); */
  scrollbar-width: none;
  min-height: calc(100vh - 213px);
}

.fixContentHome {
  padding: 5px 12px;
  overflow-x: hidden;
  height: 220px;
  min-height: calc(100vh - 247px);
  scrollbar-width: none;
}

.fixContentModal {
  padding: 10px 12px;
  overflow-x: hidden;
  height: 175px;
  min-height: calc(100vh - 275px);
}

/* sos vehilce list */

.modal-content.vehicleInput label {
  height: 100%;
  display: block;
  background: white;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 0px 2px 0px #00000026;
  position: relative;
  border: 0.3px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
}

.vehicleInput input[type="radio"]:checked + label {
  background-color: #e7270029;
}

.vehicleInput input[type="radio"]:checked + label div h2 {
  /* color: #ffffff !important; */
  color: var(--text-background-color) !important;
}

.vehicleInput input[type="radio"]:checked + label div p {
  color: #333333 !important;
}

.vehicleInput input[type="radio"] {
  display: none;
}

.vehicleInput label {
  height: 100%;
  display: block;
  background: white;
  /* padding: 1rem; */
  padding: 20px 10px;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 0px 2px 0px #00000026;
  position: relative;
  border: 0.3px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
}

/* .vehicleInput input[type="radio"]:checked + label div h2 {
  color: #ffffff !important;
} */

.vehicleInput input[type="radio"]:checked + label div p {
  color: #333333 !important;
}

/* .modal-body {
  padding: 0px !important;
} */

/* checkb0ox color css */
.checkboxSelect label {
  display: block;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
  font-size: 15px;
  padding: 5px;
}

.checkboxSelect input[type="radio"] {
  appearance: none;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
}

.checkboxSelect input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #fff;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.checkboxSelect input[type="radio"]:checked::before {
  transform: scale(1);
}

.checkboxSelect input[type="radio"]:checked {
  background-color: var(--text-background-color);
  border: 2px solid var(--text-background-color);
}

/* new health issue */
.checkboxSelect label {
  display: block;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
  font-size: 15px;
  padding: 5px;
  width: 100%;
  max-width: 275px;
}

.checkboxSelect input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
}

.checkboxSelect input[type="checkbox"]::before {
  content: "";
  width: 12px;
  height: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #fff;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.checkboxSelect input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.checkboxSelect input[type="checkbox"]:checked {
  background-color: var(--text-background-color);
  border: 2px solid var(--text-background-color);
}

.textSection {
  color: #262626;
  text-align: center;
}

/* tab css */

.tabSection.nav-tabs .nav-link {
  border: unset !important;
  padding: 5px 5px !important;
}

.tabSection.nav-tabs .nav-link {
  color: #878787 !important;
  font-weight: 500;
  font-size: 13px;
}

.tabSection.nav-tabs .nav-link svg {
  color: #878787 !important;
  font-size: 13px;
  margin-top: -2px;
  margin-left: 5px;
}

.tabSection li.nav-item {
  width: 50%;
}

.tabSection.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.tabSection.nav-tabs .nav-link.active {
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0em;
  color: var(--text-background-color) !important;
}

.tabSection.nav-tabs .nav-link.active svg {
  font-size: 13px !important;
  margin-left: 5px;
  margin-top: -2px;
  color: var(--text-background-color) !important;
}

.tabSection.nav-tabs .nav-link.active .tabImage img {
  filter: hue-rotate(90deg); /* Change the hue to your desired color */
}

@media only screen and (max-width: 400px) {
  .tabSection.nav-tabs .nav-link.active {
    font-size: 12px !important;
  }

  .tabSection.nav-tabs .nav-link {
    font-size: 12px !important;
  }
}

/* notification switch css */
.react-switch-bg {
  width: 50px !important;
  height: 28px !important;
}

.circleClass {
  border-radius: 50px;
  width: 100%;
  max-width: 40px;
  height: 40px;
  text-align: center;
  background-color: #c5c5c5;
  color: #fff;
}

/* .circleClass svg {
  margin-top: 5px;
} */
.circleClass .itemLink {
  border-radius: 50px !important;
  width: 100% !important;
  max-width: 40px !important;
  text-align: center !important;
  background-color: #c5c5c5;
  color: #fff !important;
}

.circleClass .itemLink.active {
  color: #fff !important;
  background-color: var(--text-background-color);
}

.circleClass:has(.active) {
  background-color: var(--text-background-color) !important;
}

/* facebook social css */

.SocialLoginBtn img {
  width: 40px;
}

/* .SocialLoginBtn {
  margin-right: 20px;
} */

.SocialLoginBtn button.kep-login-facebook.metro {
  background: unset !important;
  border: unset !important;
  padding: 0px !important;
}

.leaflet-container {
  position: relative;
  height: 100vh; /* Set the height to 100% of the viewport height */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fenceInput {
  width: 100%;
  max-width: 330px;
  margin: 0px auto;
  padding: 10px 10px;
  border: 1px solid #6262623d;
}

.fenceInput:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #6262623d !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.toast {
  position: absolute;
  z-index: 1;
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
  background: #ffffffa1;
  border-radius: 10px;
}

.languageModule .dropdown-toggle {
  background-color: var(--text-background-color);
  border: none;
}

.dropdown-header {
  color: #000;
  font-size: 15px;
}

svg.iconify.iconify--circle-flags {
  margin-top: -3px;
  margin-right: 5px;
}

.CameraModal {
  background-color: #000;
  /* opacity: .8; */
}

.CameraModal button.btn-close {
  background-color: #ffff !important;
  border-radius: 50% !important;
  opacity: 3;
  padding: 10px;
}
.CameraModal .modal-header {
  border-bottom: none;
}

.ScannerCamera{
  height: 75vh;
  width: 100%;
  display: grid;
  justify-content: center;
  
}
.mapItems{
  margin-top: 20px;
}
.mapContainer{
  border: 1px solid #DEDEDE;
  border-radius: 5px;
}
/* .mapContainer .leaflet-control-zoom.leaflet-bar.leaflet-control {
  display: none;
} */
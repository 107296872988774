.aboutWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  background-color: #f6f6f6;
}

.topSection {
  background-color: var(--text-background-color);
  padding: 30px 10px 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.formStart {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0px auto;
}

.forgotText {
  margin: 10px auto;
}

.forgotText p {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.backIcon {
  background-color: #fff;
  border-radius: 50%;
  height: 35px;
  margin-top: -16px;
  max-width: 35px;
  text-align: center;
  width: 100%;
}
.backIcon svg {
  margin-top: 8px;
  color: var(--text-background-color);
  font-size: 20px;
}

.contentText {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 15px;
  border-radius: 11px;
  padding: 25px 15px;
}

.contentWrapper {
  padding: 15px 10px;
  overflow-x: hidden;
  height: 220px;
  min-height: calc(100vh - 120px);
  scrollbar-width: none;
}

.profileWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  background-color: #f6f6f6;
}

.editProfileWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
}

.topItem h5 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.topSection {
  background-color: var(--text-background-color);
  padding: 30px 10px 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.formStart {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0px auto;
}

.backIcon {
  background-color: #fff;
  border-radius: 50%;
  height: 35px;
  margin-top: -16px;
  max-width: 35px;
  text-align: center;
  width: 100%;
}

.backIcon svg {
  margin-top: 5px;
  color: var(--text-background-color);
  font-size: 25px;
}

.profileTitle p {
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
}

.profileTitle {
  margin: 10px auto;
}

/* .content {
  padding: 12px 12px;
  overflow-x: hidden;
  scrollbar-width: none;
} */

.content {
  padding: 5px 12px;
  overflow-x: hidden;
  height: 220px;
  min-height: calc(100vh - 140px);
  scrollbar-width: none;
}


.userDetail {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 11px;
  padding: 10px 10px;
}

.userDetail h5 {
  color: #262626;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

.userDetail p {
  color: #626262;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.viewDetail p {
  color: var(--text-background-color);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.viewDetail svg {
  color: var(--text-background-color);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: -2px;
}

.tabOPtion {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 11px;
  padding: 10px 10px;
}

.tabContent {
  display: flex;
  padding: 8px 5px;
}

.tabContent p {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
  margin-bottom: 0px;
}

.tabContent h2 {
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  margin-top: 3px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.secirityText {
  display: flex;
  justify-content: space-between;
}

.emergencyEdit {
  display: flex;
  justify-content: end;
}

.emergencyEdit a {
  font-size: 12px;
  font-weight: 700;
  color: var(--text-background-color);
}

.tabImage img {
  color: var(--text-background-color);
}

.securityQuestion {
  margin-top: 20px;
}

.secirityText h3 {
  font-size: 15px;
  font-weight: 600;
  color: #262626;
}

.secirityText p {
  font-size: 12px;
  font-weight: 700;
  color: var(--text-background-color);
  text-decoration: underline;
}

.securityQuestion h2 {
  font-size: 13px;
  font-weight: 600;
  color: #333333;
}

.securityQuestion p {
  font-size: 10px;
  font-weight: 500;
  color: #626262;
}

.emergencyContact h3 {
  font-size: 18px;
  font-weight: 700;
  color: #262626;
}

.tabSectionTitle:hover {
  background-color: var(--text-background-color);
}

.bottomBorder {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 15px;
}

.bottomText {
  padding: 20px 20px;
}

.bottomText h2 {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.bottomText svg {
  margin-left: 5px;
  font-size: 15px;
  color: #262626;
}

.FormGroup {
  position: relative;
  margin-bottom: 30px;
}

.FormLabel {
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
}

.FormInput {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-right: 21px;
}

.inputForm {
  width: 100%;
  max-width: 375px;
  margin: 0px auto;
}

.FormGroup .FormInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.FormInput:focus {
  outline: none;
  border: 1px solid #dedede;
}
.nextBtn button {
  text-align: center;
  /* margin-top: 30px; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 14px 10px;
  border: 1px solid var(--text-background-color);
  background-color: var(--text-background-color);
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}

.tabData {
  margin-top: 20px;
}

.securityForm {
  padding: 25px 12px;
}
.editEmergencyContact {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.editEmergencyContactTitle p {
  font-size: 12px;
  font-weight: 700;
  color: var(--text-background-color);
  text-decoration: underline;
  text-align: end;
}

.contactText h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}
.optionalButton button {
  background-color: #d7d0e5;
  border: 1px solid #d7d0e5;
  border-radius: 5px;
  padding: 8px 15px;
  color: var(--text-background-color);
}

.customDropdown {
  position: absolute !important;
  top: -200px;
  left: 0;
}

.companyOptional {
  color: #4a4a4a;
  font-size: 9px;
  font-weight: 400;
  text-align: end;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .editEmergencyContact {
    display: flex;
    justify-content: space-between;
    margin-left: 13px;
    margin-right: 13px;
  }
}

@media screen and (max-width: 414px) {
  .editEmergencyContact {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 13px;
  }
}

/* notification css */

.listItemUnread {
  display: flex;
  justify-content: space-between;
  background-color: #6c757d12;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px 15px;
}
.listItemread {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px 15px;
}
.notifyTime {
  color: #626262;
  font-size: 14px;
  font-weight: 500;
}
.history {
  width: 85%;
}
.history h3 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;
}
.history p {
  margin: 0;
  font-size: 14px;
  color: #1a1919;
  word-break: break-all;
}
.TimeAgo {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-transform: lowercase !important;
}
.typeText h3 {
  font-size: 14px;
  font-weight: 500;
  color: #626262;
  margin-top: 10px;
}
.notifyIMg {
  width: 15%;
}
.notifyIMg img {
  width: 100%;
  max-width: 50px;
  margin-top: 20px;
  margin-left: 8px;
}

.noResultsMessage {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
}

.doneBtn button {
  text-align: center;
  margin-top: 30px;
  padding: 14px 10px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  border: 1px solid var(--text-background-color);
  background-color: var(--text-background-color);
  font-size: 17px;
  font-weight: 600;
}

.modalTitle {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.healthFormGroup {
  position: relative;
  margin-top: 20px;
}

.healthFormLabel {
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
}

.FormInput {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-right: 21px;
}

.healthFormGroup .FormInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #C4C4C4;
}
.FormInput:focus {
  outline: none;
  border: 1px solid #dedede;
}



.applyFilterBtn button {
  background-color: var(--text-background-color);
  border-radius: 10px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid var(--text-background-color);
  padding: 12px 25px;
  color: #fff;
  width: 100%;
  margin-top: 20px;
}

.clearFilterBtn button {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin-top: 20px;
  border: none;
  background-color: #fff;
  color: #000000;
}

.ScrollbarVertical {
  overflow-y: auto;
  padding: 7px;
  max-height: 210px;
}
/* ImageModal.module.css */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  /* background: white; */
  /* padding: 20px; */
  border-radius: 8px;
  outline: none;
  max-width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
    max-width: 400px;
    margin: 0px auto;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.closeButton {
  position: absolute;
  top: -48px;
  right: -50px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

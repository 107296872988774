.settingWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  background-color: #f6f6f6;
}

.backGround {
  background-color: var(--text-background-color);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 45px 0px 30px 20px;
  position: relative;
}

.forgotText p {
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.forgotText {
  margin: 10px auto;
}

.topItem h5 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.bellIcon svg {
  position: absolute;
  color: #ffffff;
  top: 50px;
  font-size: 25px;
  right: 10px;
}

.userDetail {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 11px;
  padding: 10px 10px;
}

.userDetail h5 {
  color: #262626;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

.userDetail p {
  color: #626262;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.viewDetail p {
  color: var(--text-background-color);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.viewDetail svg {
  color: var(--text-background-color);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: -2px;
}

.optionList {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  border-radius: 11px;
  padding: 10px 15px;
}

.optionItem {
  display: flex;
  justify-content: space-between;
}

.optionItem h5 {
  font-size: 14px;
  font-weight: 600;
  color: #262626;
  padding: 10px 5px;
}

.optionItem svg {
  color: #a5a5a5;
  font-size: 25px;
}

.logOutBtn button {
  text-align: center;
  margin-top: 30px;
  padding: 15px 10px;
  border: 1px solid var(--text-background-color);
  background-color: var(--text-background-color);
  border-radius: 10px;
  width: 100%;
  color: #fff;
  border: 1px solid var(--text-background-color);
  font-size: 17px;
  font-weight: 600;
}

/* reset css */

.topSection {
  background-color: var(--text-background-color);
  padding: 30px 10px 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.formStart {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0px auto;
}

.resetLockImg {
  text-align: center;
  /* background-color: #4063941f; */
  border-radius: 50%;
  width: 100%;
  max-width: 160px;
  height: 160px;
  margin: 0px auto;
}

.resetLockImg img {
  width: 100%;
  max-width: 120px;
  margin-top: 20px;
}

.resetWrapper {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 15px;
  border-radius: 26px;
  padding: 25px 15px;
}

.overLay {
  padding: 15px 15px;
}

.wrapperSection {
  width: 100%;
  max-width: 375px;
  margin: 0px auto;
}

.backIcon {
  background-color: #fff;
  border-radius: 50%;
  height: 35px;
  margin-top: -16px;
  max-width: 35px;
  text-align: center;
  width: 100%;
}

.backIcon svg {
  margin-top: 5px;
  color: var(--text-background-color);
  font-size: 25px;
}

.FormGroup .FormInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.FormInput:focus {
  outline: none;
  border: 1px solid #dedede;
}
.FormGroup {
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
}

.FormLabel {
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
}

.FormInput {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 8px;
}
.notificationTick {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #9f9f9f;
  position: absolute;
  top: 50px;
  right: 11px;
  z-index: 1;
}

.topSection {
  background-color: var(--text-background-color);
  padding: 30px 10px 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.backIcon {
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  width: 100%;
  max-width: 35px;
  height: 35px;
  margin-top: -16px;
}

.forgotText p {
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}

.forgotText {
  margin: 10px auto;
}

.formStart {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0px auto;
}

.backIcon svg {
  margin-top: 5px;
  color: var(--text-background-color);
  font-size: 25px;
}

.lockImg {
  width: 100%;
  max-width: 180px;
  margin: 20px auto;
}

.lockImg img {
  width: 100%;
  max-width: 180px;
}

.keyIcon {
  width: 100%;
  max-width: 75px;
  margin: 20px auto;
  background-color: var(--text-background-color);
  border-radius: 50%;
  height: 75px;
}

.keyIcon svg {
  width: 100%;
  margin: 14px auto;
  color: #fff;
  font-size: 45px;
}

.tittleText {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.tittleText h3 {
  font-size: 28px;
  font-weight: 800;
  color: #262626;
  margin-bottom: 20px;
}

.tittleText p {
  font-size: 14px;
  font-weight: 400;
  color: #626262;
  width: 100%;
  max-width: 264px;
  margin: 0px auto;
  line-height: 22px;
}

.FormGroup .FormInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.FormInput:focus {
  outline: none;
  border: 1px solid #dedede;
}
.FormGroup {
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
}

.FormLabel {
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
}

.FormInput {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 8px;
}

.forgotButton button {
  text-align: center;
  margin-top: 90px;
  padding: 14px 10px;
  border: 1px solid var(--text-background-color);
  background-color: var(--text-background-color);
  border-radius: 10px;
  width: 100%;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}

.otpInputLabel {
  color: #6d6d6d;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .wrapper {
    width: 100%;
    max-width: 375px;
    margin: 0px auto;
  }
}

@media screen and (max-width: 575px) {
  .tittleText h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .tittleText p {
    font-size: 14px;
    width: 100%;
    max-width: 260px;
    margin: 0px auto;
    line-height: 1.8;
  }
}


@media screen and (max-width: 360px) {
  .forgotButton button {
    margin-top: 15px !important;
  }
  .FormGroup {
    margin-bottom: 25px !important;
  }
  
}


@media screen and (max-width: 375px) {
  .forgotButton button {
    margin-top: 20px;
  }
}
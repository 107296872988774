/* ===== Home Css Start ==== */
.logoSection {
  width: 100%;
  display: flex;
  margin: 0px auto;
  justify-content: center;
}

.logoSection img {
  width: 100%;
  max-width: 120px;
  margin-top: 35px;
  background-color: #F8F8FF;
  border-radius: 60px;
  padding: 8px;
}

@media screen and (min-width: 768px) {
  .backgroundImg {
    width: 100%;
    max-width: 400px;
    height: 100vh;
    margin: 0px auto;
  }
}

/* ===== Home Css End ==== *

/* ===== Homepage Css Start ==== */
.homePageLogo {
  display: flex;
  justify-content: center;
}

.homePageLogo img {
  width: 100%;
  max-width: 150px;
  margin: 200px auto;
}

@media screen and (max-width: 768px) {
  .homePageLogo {
    display: flex;
    width: 100%;
    position: relative;
  }
  .homePageLogo img {
    position: absolute;
    bottom: 0px;
    top: 50px;
    left: 0px;
    right: 0px;
    width: 100%;
    max-width: 120px;
  }
}

/* ==== Homepage Css End  ====*/